/**
 * PinImages is meant to offer an accessible way to change the pin images for result pin
 * on the interactive map page. Given some config, an SVG should be customizable to
 * have branding consistent styling in this file.
 */
class PinImages {
  /**
   * @param {Object} defaultPinConfig The configuration for the default pin
   * @param {Object} hoveredPinConfig The configuration for the hovered pin
   * @param {Object} selectedPinConfig The configuration for the selected pin
   */
  constructor(defaultPinConfig = {}, hoveredPinConfig = {}, selectedPinConfig = {}) {
    this.defaultPinConfig = defaultPinConfig;
    this.hoveredPinConfig = hoveredPinConfig;
    this.selectedPinConfig = selectedPinConfig;
  }

  /**
   * Generate standard theme pin given some parameters
   * @param {string} pin.backgroundColor Background color for the pin
   * @param {string} pin.strokeColor Stroke (border) color for the pin
   * @param {string} pin.labelColor Label (text) color for the pin
   * @param {number} pin.width The width of the pin
   * @param {number} pin.height The height of the pin
   * @param {string} pin.pinCount The index of the pin for the pin text
   * @return {Object} The SVG of the pin, and its width and height
   */
  generatePin ({
    backgroundColor = '#00759e',
    strokeColor = 'black',
    labelColor = 'white',
    width = 20,
    height= 27,
    index = '',
    profile = ''
  } = {}) {
    const svg = `
      <svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;}
</style>
<path d="M85,42C85,22.7,69.3,7,50,7C30.7,7,15,22.7,15,42c0,27.6,35,51,35,51S85,69.6,85,42z"/>
<g>
	<path class="st0" d="M59.5,61.4c-1.6,0-3-0.3-4.2-0.9c-5.3-2.6-3.9-8.8-2-14.5c0.9-2.5,1.5-4.4,1.9-5.6c-1.1,0.4-2.3,0.8-3.6,1.3   c-3,1-5.5,1.9-7.6,2.8l-0.6,1.6c-0.1,0.2-2.1,6.4-4.6,9.7c-1.1,1.5-2.7,2.5-4.2,2.7c-0.9,0.1-1.6,0-2.2-0.5c-1.6-1.2-1.6-4.1,0-6.9   c1.9-3.3,4.7-5.7,10.1-8.1l4.6-13.4c-1.4-0.2-2.9-0.6-4.5-1c-6.6-1.7-8-0.6-9.1,0.2c-0.6,0.4-1,1.2-1.1,1.9   c-0.1,0.7,0.1,1.4,0.5,1.9c0.4,0.5,1,0.8,1.7,0.9c0.7,0.1,1.3-0.1,1.8-0.6c0.3-0.3,0.6-0.6,0.7-1c0.2-0.5,0.7-0.7,1.2-0.5   c0.5,0.2,0.7,0.7,0.5,1.2c-0.3,0.7-0.7,1.2-1.3,1.7c-0.9,0.7-2,1.1-3.2,1c-1.2-0.1-2.2-0.7-2.9-1.6c-0.7-0.9-1-2.1-0.9-3.3   c0.2-1.3,0.8-2.5,1.8-3.2c1.7-1.3,3.8-2.3,10.7-0.5c1.7,0.4,3.3,0.8,4.7,1l0.4-1.3c0.5-1.3,1.3-2.3,2.3-2.8c0.7-0.3,1.3-0.4,2-0.1   c0.7,0.2,1.2,0.8,1.4,1.5c0.3,0.9,0,2-0.8,3.1c-0.9,1.2-2.3,1.6-3.9,1.6L45,42.1c1.8-0.7,3.8-1.5,6.2-2.2c1.9-0.6,3.5-1.2,5-1.8   c0.5-1.6,2.3-6.4,4.4-9.2c1.1-1.5,2.7-2.5,4.2-2.7c0.9-0.1,1.6,0,2.2,0.5c1.5,1.2,1.4,3.8-0.4,6.7c-1.5,2.5-3.8,4.1-9,6.2   c-0.3,0.8-1,3.1-2.4,7c-2.5,7.2-2.2,10.6,1,12.3c2.4,1.2,6.5,1,9.3-1.4c1.5-1.3,2.3-2.3,2.5-4.4c0.1-1.5-1-3-2.3-3.1   c-0.7-0.1-1.3,0.1-1.9,0.6c-0.5,0.4-0.9,1.1-0.9,1.7c0,0.4,0,0.8,0.2,1.2c0.2,0.5,0,1-0.5,1.2c-0.5,0.2-1,0-1.2-0.5   c-0.3-0.7-0.4-1.4-0.3-2.1c0.1-1.2,0.7-2.2,1.6-3c0.9-0.7,2.1-1.1,3.2-1c2.4,0.2,4.2,2.6,4,5.1c-0.2,2.7-1.4,4.1-3.1,5.6   C64.5,60.6,61.9,61.4,59.5,61.4z M41.8,45.5c-4.4,2.2-6.2,4.1-7.6,6.5c-1.3,2.3-1,4.1-0.5,4.5c0.1,0.1,0.4,0.2,0.8,0.1   c0.8-0.1,2-0.7,3-2C39.8,51.7,41.7,46,41.8,45.5z M65.3,28c-0.1,0-0.2,0-0.3,0c-0.8,0.1-2,0.7-3,2c-1.5,2-2.8,5.1-3.6,7.2   c3.7-1.6,5.5-3,6.6-4.8c1.4-2.3,1.3-3.9,0.8-4.3C65.7,28.1,65.5,28,65.3,28z M51.8,25.4c-0.1,0-0.3,0-0.4,0.1   c-0.5,0.3-1,0.9-1.3,1.7L49.8,28c0.8-0.1,1.4-0.3,1.8-0.8c0.5-0.7,0.6-1.2,0.5-1.5c0-0.1-0.1-0.2-0.2-0.2   C51.9,25.4,51.8,25.4,51.8,25.4z"/>
</g>
</svg>`;
    return { svg, width, height };
  };

  /**
   * Get the default pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getDefaultPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.defaultPinConfig.backgroundColor,
      strokeColor: this.defaultPinConfig.strokeColor,
      labelColor: this.defaultPinConfig.labelColor,
      width: 30,
      height: 34,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the hovered pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getHoveredPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.hoveredPinConfig.backgroundColor,
      strokeColor: this.hoveredPinConfig.strokeColor,
      labelColor: this.hoveredPinConfig.labelColor,
      width: 30,
      height: 38,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the selected pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getSelectedPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.selectedPinConfig.backgroundColor,
      strokeColor: this.selectedPinConfig.strokeColor,
      labelColor: this.selectedPinConfig.labelColor,
      width: 30,
      height: 38,
      index: '',
      profile: profile
    });
  }
}

export { PinImages };
